
  import { LeadActivity } from '@/entities/crm'
  import dayjs from 'dayjs'
  import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
  export default class ActivityListItem extends Vue {
  @Prop() activity: LeadActivity
  cssStyles = {}
  utcOffset = dayjs().utcOffset()

  // Methods
  mounted () {
    this.cssStyles = {
      '--color': this.activity.type.color,
    }
  }

  // Getters

  // Watchers
  }
