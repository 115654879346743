
  import { LeadActivity } from '@/entities/crm'
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import ActivityListItem from './ActivityListItem.vue'
  import { GForm } from '@/components/forms/GForm'

@Component({
  components: {
    ActivityListItem,
  },
})
  export default class TodayActivityList extends GForm {
    @Prop() activities: LeadActivity[];
    @Prop() targetDate;

    currentDate = dayjs().format('DD/MM/YYYY')
    today = dayjs().format('DD/MM/YYYY')
    todayActivities: LeadActivity[] = []
    utcOffset = dayjs().utcOffset()

    // Methods
    mounted () {
      if (this.targetDate !== '') {
        this.currentDate = dayjs(this.targetDate).format('DD/MM/YYYY')
      }
    }

    // Getters

    // Watchers
    @Watch('activities')
    updateTodayActivities () {
      const filteredActivities = this.activities.filter(activity => {
        return activity.schedulingDate
                .add(this.utcOffset, 'minute')
                .format('DD/MM/YYYY') === this.currentDate
      })

      this.todayActivities = filteredActivities.sort((firstEvent, secondEvent) => {
        if (firstEvent.schedulingDate > secondEvent.schedulingDate) return 1
        return -1
      })
    }

    @Watch('targetDate')
    onTargetDateChange () {
      this.currentDate = dayjs(this.targetDate).format('DD/MM/YYYY')
      this.updateTodayActivities()
    }
  }
